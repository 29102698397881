<template>
  <b-card title="Поступления">
    <b-card-body>
      <b-overlay :show="isLoading" rounded="sm">
        <b-table :fields="fields" :items="items" responsive>
          <template #cell(month)="data">
            {{ data.item.month }} - {{ data.item.year }}
          </template>
        </b-table>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'HomeTransactions',
  data() {
    return {
      fields: [
        { key: 'month', label: 'Дата' },
        { key: 'accumulated_perc', label: 'Начисленные %' },
        { key: 'accumulated_fine', label: 'Пеня' },
        { key: 'payment_debt', label: 'Платежи ОД' },
        { key: 'payment_perc', label: 'Платежи %' },
        { key: 'late_perc', label: 'в т.ч. проср %' },
        { key: 'payment_fine', label: 'Платежи пеня' },
        { key: 'fines', label: 'Платежи штрафы' },
        { key: 'portfolio_increase', label: 'Прирост' },
        { key: 'clean_increase', label: 'Чистый прирост' },
      ],
      items: [],
      isLoading: false,
    }
  },
  created() {
    this.getPaymentStats()
  },
  methods: {
    async getPaymentStats() {
      this.isLoading = true
      const res = await this.$http.get('admin/payment-stats/')
      this.items = res.data
      this.isLoading = false
    },
  },
}
</script>
