<template>
  <b-card title="Калькулятор">
    <b-row>
      <b-col md="4">
        <b-form-group label="Сумма">
          <b-form-input v-model="value" type="number" @input="updatePayment" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Срок">
          <b-form-input v-model="period" type="number" @input="updatePayment" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Тип">
          <b-form-select
            v-model="periodType"
            :options="periods"
            text-field="title"
            value-field="value"
            @input="updatePayment"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Ставка, в год">
          <b-form-select
            v-model="rate"
            :options="terms"
            text-field="title"
            value-field="value"
            @input="updatePayment"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        Ежемесячный платеж: <b>{{ payment }} BYN</b>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'HomeCalculator',
  data() {
    return {
      value: 0,
      period: 6,
      periodType: 'm',
      rate: 1.022,
      payment: 0,
      periods: [
        { title: 'дней', value: 'd' },
        { title: 'мес.', value: 'm' },
      ],
      terms: [
        { title: 'До 3 мес, 5000, 120.45%', value: 1.2045 },
        { title: 'До 3 мес, 100000, 109.5%', value: 1.095 },
        { title: 'До 24 мес, 30000, 102.2%', value: 1.022 },
        { title: 'До 24 мес, 60000, 91.25%', value: 0.9125 },
        { title: 'До 24 мес, 100000, 65.7%', value: 0.657 },
      ],
    }
  },
  methods: {
    updatePayment() {
      let monthlyRate = this.rate / 12
      let koef = 1
      if (this.periodType === 'm') {
        monthlyRate = this.rate / 12
        koef =
          (monthlyRate * (1 + monthlyRate) ** this.period) /
          ((1 + monthlyRate) ** this.period - 1)
      } else {
        monthlyRate = this.rate / 365
        koef = 1 + monthlyRate * this.period
      }
      this.payment = Math.round(this.value * koef)
    },
  },
}
</script>
