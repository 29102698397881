<template>
  <apexchart
    id="perc-chart"
    type="bar"
    width="100%"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ChartPercents',
  components: {
    apexchart: VueApexCharts,
  },
  props: ['percSeries'],
  data() {
    return {
      tab: 0,
      series: [
        {
          name: 'Начисленные %',
          data: this.percSeries,
        },
        {
          name: 'Уплаченные %',
          data: [0, 1, 1.5],
        },
      ],
      options: {
        chart: {
          id: 'perc-chart',
        },
        xaxis: {
          categories: ['Январь', 'Февраль', 'Март'],
        },
      },
    }
  },
}
</script>
