<template>
  <b-card title="Поступления">
    <b-tabs v-model="tab" lazy>
      <b-tab title="Проценты">
        <b-card-body>
          <ChartPercents :perc-series="chartData.percents" />
        </b-card-body>
      </b-tab>
      <b-tab title="Основной долг">
        <b-card-body>
          <ChartDebts :debt-series="chartData.debts" />
        </b-card-body>
      </b-tab>
      <b-tab title="Платежи">
        <b-card-body>
          <ChartPayments :payment-series="chartData.payment" />
        </b-card-body>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import ChartPercents from '@/views/home/components/ChartPercents'
import ChartDebts from '@/views/home/components/ChartDebts'
import ChartPayments from '@/views/home/components/ChartPayments'
export default {
  name: 'Charts',
  components: { ChartPayments, ChartDebts, ChartPercents },
  data() {
    return {
      tab: 0,
      chartData: {},
    }
  },
  mounted() {
    this.getCharts()
  },
  methods: {
    async getCharts() {
      const res = await this.$http.get('admin/charts/')
      this.chartData = res.data
    },
  },
}
</script>
