<template>
  <apexchart
    id="debt-chart"
    type="bar"
    width="100%"
    height="auto"
    :options="options"
    :series="series"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ChartDebts',
  components: {
    apexchart: VueApexCharts,
  },
  props: ['debtSeries'],
  data() {
    return {
      tab: 0,
      series: [
        {
          name: 'Выставленный ОД',
          data: this.debtSeries,
        },
        {
          name: 'Уплаченный ОД',
          data: [100, 100, 100],
        },
      ],
      options: {
        chart: {
          id: 'debt-chart',
        },
        xaxis: {
          categories: ['Январь', 'Февраль', 'Март'],
        },
      },
    }
  },
}
</script>
