<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Статистика</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col md="4" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon icon="PhoneCallIcon" size="24" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.new_calls || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Новые звонки
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="4" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-warning">
                <feather-icon icon="PenToolIcon" size="24" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.new_applications || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Новые заявки
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="4" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon icon="CheckCircleIcon" size="24" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.active_deals || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Активные сделки
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col md="4" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-secondary">
                <feather-icon icon="DollarSignIcon" size="24" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.balance || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Сумма долга </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="4" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon icon="PercentIcon" size="24" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.perc || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Начисленные проценты
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="4" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <feather-icon icon="AlertCircleIcon" size="24" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stats.fine || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Начисленная пеня
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'HomeStats',
  data() {
    return {
      stats: {},
    }
  },
  created() {
    this.getStats()
  },
  methods: {
    async getStats() {
      const res = await this.$http.get('admin/stats/')
      this.stats = res.data
    },
  },
}
</script>
